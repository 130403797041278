<template>
  <!-- 快递公司 -->
  <div class="container">
    <el-card class="head">
      <div>
        <div class="OperButton">
          <router-link to="/setting/express/add">
            <div class="addBtn">
              <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
            </div>
          </router-link>
        </div>
      </div>
    </el-card>
    <div style="height: 10px"></div>
    <el-card class="body">
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column
            prop="expressName"
            label="快递公司名称"

            align="center"
          >
          </el-table-column>
          <el-table-column prop="expressCode" label="快递公司缩写" align="center">
          </el-table-column>
          <el-table-column prop="firstWeight" label="首件(kg)" align="center">
          </el-table-column>
          <el-table-column prop="baseFree" label="运费(元)" align="center">
          </el-table-column>
          <el-table-column prop="stepwiseWeight" label="续件(kg)" align="center">
          </el-table-column>
          <el-table-column prop="additionalFee" label="运费(元)" align="center">
          </el-table-column>
          <el-table-column prop="upperWeight" label="重量上限(kg)" align="center">
          </el-table-column>
          <el-table-column prop="lowerWeight" label="重量下限(kg)" align="center">
          </el-table-column>
          <el-table-column prop="dueTime"  label="当日达截止时间" align="center">
          </el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="expressEdit(scope.row.id)">
                  <img style="width: 18px;height: 18px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="expressDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import { expressList, expressDel } from "@/api/express";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      tableData: [],
      total: null,
      currPage: "",
    };
  },
  activated() {
    this.list();
  },
  methods: {
    // table
   tableHeaderColor({ rowIndex }) {
     if (rowIndex === 0) {
       return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
     }
   },
    //获取快递公司列表
    list() {
      let self = this;
      expressList({ currPage: self.currPage }).then((res) => {
        self.tableData = res.data.list;
        self.total = res.data.total * 1;
      });
    },
    //获取页数
    handleCurrentChange(page) {
      this.currPage = page;
      this.list();
    },
    //删除快递公司
    async expressDel(id) {
      if (!(await this._box())) return;
      expressDel({ id: id }).then(() => {
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage = this.currPage > totalPage ? totalPage : this.currPage;
        this.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
    //编辑
    expressEdit(id) {
      this.$router.push({
        path: "/setting/express/edit",
        query: { id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";
/deep/ .head .el-card__body{
    padding-left: 0px;
  }
  .dataOper{
    padding: 20px;
    padding-left: 0px;
  }
</style>
